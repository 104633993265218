// extracted by mini-css-extract-plugin
export const root = "footer-module--root--2cGG9";
export const isModalView = "footer-module--isModalView--3SB2U";
export const modalButton = "footer-module--modalButton--20do1";
export const pagination = "footer-module--pagination--3bZtQ";
export const arrow = "footer-module--arrow--1_WlW";
export const footer = "footer-module--footer--2-hg1";
export const address = "footer-module--address--1oG-6";
export const nav = "footer-module--nav--3rxs9";
export const isActive = "footer-module--isActive--2NYHh";
export const social = "footer-module--social--2IRn3";
export const formContainer = "footer-module--formContainer--lcIOn";
export const modalCloseButton = "footer-module--modalCloseButton--2chJ1";
export const developer = "footer-module--developer--1xiLy";