// extracted by mini-css-extract-plugin
export const root = "form-module--root--QnNQZ";
export const vertical = "form-module--vertical--XlnEt";
export const title = "form-module--title--2OLKX typography-module--title1--3kWAX";
export const mobileFlex = "form-module--mobileFlex--14FN-";
export const list = "form-module--list--3uuz-";
export const textarea = "form-module--textarea--2JgmR";
export const broker = "form-module--broker--VJeXD";
export const smallPrint = "form-module--smallPrint--3KxFB";
export const submit = "form-module--submit--21AZ1";
export const dark = "form-module--dark--2oJNA";
export const successfulRegister = "form-module--successfulRegister--3-cbq typography-module--title1--3kWAX";
export const errors = "form-module--errors--1PRp9";