import React from "react";
import { cn } from "../lib/helpers";
import * as styles from "./input.module.css";

class Input extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isActive: false,
    }
  }

  onFocusHandler = () => {
    this.setState({ isActive: true })
  }

  onBlurHandler = () => {
    const { value } = this.props

    if (value === '') {
      this.setState({ isActive: false })
    }
  }

  render() {
    const { type, label, name, isRequired, handleChange, value, error, theme } = this.props
    const { isActive } = this.state

    return (
      <label className={cn(styles.root, isActive ? styles.isActive : '', error ? styles.hasError : '', theme == 'dark' ? styles.dark : '')}>
        <input className={styles.input} type={type} aria-labelledby={`${name}-label`} name={name} id={name} value={value} onChange={handleChange} onFocus={() => this.onFocusHandler()} onBlur={() => this.onBlurHandler()} autoComplete="#"/>
        <span className={styles.label} id={`${name}-label`}>{`${label}${isRequired ? '*' : ''}`}</span>
        <span className={styles.border}></span>
      </label>
    )
  }
}

export default Input;