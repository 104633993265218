import React, { useRef } from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import { cn } from "../lib/helpers";
import Logo from "../../static/legion-logo.inline.svg";
import BlockText from "./block-text";
import Form from "./form";
import * as styles from "./footer.module.css";

const query = graphql`
  query SiteInfoQuery {
    info: sanityInformation {
      _rawAddress
      fairHousingNoticeUrl
      csStandardBrokerProceduresDoc {
        asset {
          url
        }
      }
      email
      phoneNumber
    }
  }
`;

const Footer = (props) => (
  <StaticQuery
    query={query}
    render={data => {
      const info = data.info;
      const { pagination, hideForm, hideButton, isModalView } = props;

      if (!data.info) {
        throw new Error(
          'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
        );
      }

      const { _rawAddress, fairHousingNoticeUrl, csStandardBrokerProceduresDoc } = info;

      return (
        <div className={cn(styles.root, isModalView ? styles.isModalView : '')}>
          {!hideButton && (
            <button type="button" className={styles.modalButton}><Link to="/inquire"><span>Inquire</span></Link></button>
          )}
          {!hideForm && (
            <div className={styles.formContainer}>
              <Form />
            </div>
          )}
          {pagination && (
            <Link className={styles.pagination} to={pagination.link}>{pagination.link === '/' ? pagination.title : `Continue to ${pagination.title}`} <div className={styles.arrow}></div></Link>
          )}
          <hr />
          <div className={styles.footer}>
            <div className={styles.address}>
              <BlockText blocks={_rawAddress} />
            </div>
            <div className={styles.nav}>
              <ul>
                <li><Link to="/news" activeClassName={styles.isActive}><span>News</span></Link></li>
                <li><Link to="/legal" activeClassName={styles.isActive}><span>Legal</span></Link></li>
                <li><a href={fairHousingNoticeUrl} target="_blank" rel="noreferer noopener"><span>Fair Housing Notice</span></a></li>
                {csStandardBrokerProceduresDoc &&
                  <li><a href={csStandardBrokerProceduresDoc.asset?.url} target="_blank" rel="noreferer noopener">CS Standard Operating Procedures</a></li>
                }
                <li><a href="https://www.instagram.com/109east79/" target="_blank" rel="noreferer noopener"><span>Instagram</span></a></li>
              </ul>
            </div>
            <div className={styles.social}>
              <ul>
                <li className={styles.developer}>
                  <span>Developer:</span>
                  <a href="https://www.legioninvestmentgroup.com/" target="_blank" rel="noreferer noopener">
                    <Logo />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    }}
  />
);

export default Footer;
