import React from "react";
import axios from "axios";
import { cn } from "../lib/helpers";
import Input from "./input";
import * as styles from "./form.module.css";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      status: null,
      registered: false,
      errors: {
        fname: false,
        lname: false,
        email: false,
        phone: false,
        brokerage: false,
      },
      requiredValues: {
        fname: '',
        lname: '',
        email: '',
        phone: '',
        brokerage: '',
      },
      contact: 'email',
      isBroker: false,
    }
  }


  handleChange = (e) => {
    const { requiredValues, errors } = this.state
    const { name, value: newValue, type } = e.target
    let newError;

    if (type === 'email') {
      newError = this.emailValidation(newValue)
    } else {
      newError = this.nameValidation(newValue)
    }

    // save field values
    this.setState({
      requiredValues: {
        ...requiredValues,
        [name]: newValue,
      },
      errors: {
        ...errors,
        [name]: !newError
      }
    })
  }

  handleOnSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const { requiredValues, contact } = this.state
    this.setState({ submitting: true })

    if (this.emailValidation(requiredValues.email) && this.nameValidation(requiredValues.lname) && this.nameValidation(requiredValues.fname)) {
      axios({
        method: "post",
        url: "https://getform.io/f/9439d871-8cc5-49ac-ab13-d2b4a73b3ea3",
        data: new FormData(form)
      })
      .then((r) => {
        this.handleServerResponse(true, "Subcribed Successfully", form)
      })
      .catch((r) => {
        this.handleServerResponse(false, r.response.data.error, form)
      })
    } else {
      let fnameError = false
      let lnameError = false
      let emailError = false
      let phoneError = false

      if (!this.emailValidation(requiredValues.email)) {
        emailError = true
      }
  
      if (!this.nameValidation(requiredValues.fname)) {
        fnameError = true
      }
  
      if (!this.nameValidation(requiredValues.lname)) {
        lnameError = true
      }
  
      if (contact !== 'email' && !this.nameValidation(requiredValues.phone)) {
        phoneError = true
      }

      this.setState({
        errors: {
          email: emailError,
          fname: fnameError,
          lname: lnameError,
          phone: phoneError
        }
      })
    }
  }

  nameValidation = (fieldValue) => {
    if (fieldValue.trim() === '') {
      return false
    }
    return true;
  };
  
  emailValidation = (email) => {
    if (
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email,
      )
    ) {
      return true;
    }
    return false
  };

  handleServerResponse = (ok, msg, form) => {
    this.setState({
      submitting: false,
      status: { ok, msg }
    })

    if (ok) {
      form.reset()
      this.setState({
        registered: true
      })
    }
  }

  handleContactChange = (e) => {
    this.setState({ contact: e.target.value })
  }

  handleBrokerChange = (e) => {
    let isBroker
    if (e.target.value === 'yes') {
      isBroker = true
    } else {
      isBroker = false
    }
    this.setState({ isBroker })
  }

  render() {
    const theme = this.props.theme;
    const {
      submitting,
      errors,
      requiredValues,
      contact,
      isBroker,
      status,
      registered
    } = this.state;

    return (
      <React.Fragment>
        {!registered && (
          <React.Fragment>
            <h2 className={styles.title}>Inquire</h2>
            <form onSubmit={(e) => this.handleOnSubmit(e)} className={cn(styles.root, theme == 'dark' ? styles.dark : '')} autoComplete="#">
              <fieldset>
                <Input 
                  type="text" 
                  name="fname"
                  value={requiredValues ? requiredValues.fname : ''}
                  label="First Name"
                  handleChange={this.handleChange}
                  isRequired
                  error={errors ? errors.fname : ''}
                  theme={theme}
                />
                <Input 
                  type="text"
                  name="lname"
                  value={requiredValues ? requiredValues.lname : ''}
                  label="Last Name"
                  handleChange={this.handleChange}
                  isRequired
                  error={errors ? errors.lname : ''}
                  theme={theme}
                />
              </fieldset>
              <fieldset>
                <Input 
                  type="email" 
                  name="email" 
                  label="Email"
                  value={requiredValues ? requiredValues.email : ''}
                  handleChange={this.handleChange}
                  isRequired
                  error={errors ? errors.email : ''}
                  theme={theme}
                />
              </fieldset>
              {contact !== 'email' && <fieldset>
                <Input 
                  type="text"
                  name="phone"
                  label="Phone Number"
                  value={requiredValues ? requiredValues.phone : ''}
                  handleChange={this.handleChange}
                  isRequired
                  error={errors ? errors.phone : ''}
                  theme={theme}
                />
              </fieldset>}
              <fieldset className={styles.mobileFlex}>
                <div>
                  <p>How would you like to be contacted?</p>
                </div>
                <div>
                  <ul className={styles.list}>
                    <li>
                      <input id="contact-email" type="radio" name="contact" value="email" onChange={(e) => this.handleContactChange(e)} checked={contact === 'email'} />
                      <label htmlFor="contact-email">Email</label>
                    </li>
                    <li>
                      <input id="contact-text" type="radio" name="contact" value="text" onChange={(e) => this.handleContactChange(e)} checked={contact === 'text'} />
                      <label htmlFor="contact-text">Text</label>
                    </li>
                    <li>
                      <input id="contact-phone" type="radio" name="contact" value="phone" onChange={(e) => this.handleContactChange(e)} checked={contact === 'phone'} />
                      <label htmlFor="contact-phone">Phone</label>
                    </li>
                  </ul>
                </div>
              </fieldset>
              <fieldset>
                <select name="size" id="size">
                  <option value="">Residence Size</option>
                  <option value="2 Bedrooms">2 Bedrooms</option>
                  <option value="3 Bedrooms">3 Bedrooms</option>
                  <option value="4 Bedrooms">4 Bedrooms</option>
                  <option value="5 Bedrooms">5 Bedrooms</option>
                </select>
                <select name="price" id="price">
                  <option value="">Price</option>
                  <option value="$5m – $10m">$5m &ndash; $10m</option>
                  <option value="$10m – $15m">$10m &ndash; $15m</option>
                  <option value="$15m – $20m">$15m &ndash; $20m</option>
                  <option value="$20m – $25m">$20m &ndash; $25m</option>
                  <option value="$25m – $30m">$25m &ndash; $30m</option>
                  <option value="$30m – $35m">$30m &ndash; $35m</option>
                </select>
              </fieldset>
              <fieldset className={styles.broker}>
                <div>
                  <p>Are you a broker?</p>
                  <ul className={styles.list}>
                    <li>
                      <input id="broker-no" type="radio" name="broker" value="no" checked={!isBroker} onChange={(e) => this.handleBrokerChange(e)} />
                      <label htmlFor="broker-no">No</label>
                    </li>
                    <li>
                      <input id="broker-yes" type="radio" name="broker" value="yes" checked={isBroker} onChange={(e) => this.handleBrokerChange(e)} />
                      <label htmlFor="broker-yes">Yes</label>
                    </li>
                  </ul>
                </div>
                {isBroker && 
                  <Input 
                    type="text" 
                    name="brokerage" 
                    label="Brokerage firm"
                    value={requiredValues ? requiredValues.brokerage : ''}
                    handleChange={this.handleChange}
                    isRequired
                    error={errors ? errors.brokerage : ''}
                    theme={theme}
                  />
                }
              </fieldset>
              <fieldset className={styles.vertical}>
                <textarea name="criteria" id="criteria" rows="3" className={styles.textarea} placeholder="Tell us what you are looking for."></textarea>
                <div><p className={styles.smallPrint}>* Required</p></div>
              </fieldset>

              <button type="submit" className={styles.submit} disabled={submitting}><span>Submit</span></button>

              {status && (
                <div className={styles.errors}>
                  <p>{status.msg}</p>
                </div>
              )}
            </form>
          </React.Fragment>
        )}
        {registered && (
          <div className={styles.successfulRegister}>
            <p>Thank you, <br/>we will be in touch with <br/>more information.</p>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Form;